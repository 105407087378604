:root {
  --main-background: #311002;
  --secondary-background: #401502;
  --secondary-background-active: #6b2303;
  --third-background: #622004;
  --dark-background: #260d01;
  --highlight-color: #F87944;
  --text-color: #a9b0c7;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  background: var(--main-background); 
}
.emoji-picker-modal, .epr-preview, .epr-body, .epr-header, .epr-main, .EmojiPickerReact, .epr-dark-theme, .EmojiPickerReact.epr-dark-theme > *{
            
            
  --epr-bg-color: #591d07!important;
  --epr-category-label-bg-color: var(--secondary-background)!important;
  --epr-text-color: rgba(248, 121, 68, 0.8) !important;
  --epr-hover-bg-color: var(--highlight-color) !important;

  --epr-focus-bg-color: var(--highlight-color) !important;
  
  --epr-search-input-bg-color: var(--dark-background) !important;

  --epr-picker-border-color: #151617 !important;

  --epr-search-input-bg-color-active: var(--epr-dark) !important;
  --epr-emoji-variation-indicator-color: var(--highlight-color) !important;
  --epr-category-icon-active-color: blue !important;
  
  --epr-skin-tone-picker-menu-color: #22222295 !important;
}